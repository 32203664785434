import React, { useState } from 'react';

import { Link } from 'gatsby';

import { useScrollHandler } from "../hooks/scroll";
import * as Icon from "react-feather";

const Menu = ({ top }) => {
    const [menu, setMenu] = useState(false);

    return (
        <section className={ useScrollHandler() ? "top" : "top scrolled" }  style={top === true ? {top: '0px'} : {bottom: '0px'}}>
            <div className={menu ? "mobile-menu d-block" : "d-none"}>
                <ul className="list-unstyled">
                    <li>
                        <Link to="/" activeClassName="active" children="Nyheter" />
                    </li>
                    <li>
                        <Link to="/sortiment" activeClassName="active" children="Sortiment" />
                    </li>
                    <li>
                        <Link to="/omoss" activeClassName="active" children="Om oss" />
                    </li>
                    <li>
                        <Link to="/kontaktinformation" activeClassName="active" children="Kontakt information" />
                    </li>
                </ul>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-6 col-lg-3">
                        <Link to="/" style={{ textDecoration: 'none', color: '#fff' }}>
                            <span className="logo">Antikviteten</span>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-6 text-center">
                        <div className="mobilemenu w-100 text-right d-block d-lg-none">
                            {menu ? (
                                <Icon.X onClick={() => setMenu(false)} />
                            ) : (
                                <Icon.Menu onClick={() => setMenu(true)} />
                            )}
                        </div>
                        <ul className="list-inline d-none d-lg-block">
                            <li className="list-inline-item">
                                <Link to="/" activeClassName="active" children="Nyheter" />
                            </li>
                            <li className="list-inline-item">
                                <Link to="/sortiment" activeClassName="active" children="Sortiment" />
                            </li>
                            <li className="list-inline-item">
                                <Link to="/omoss" activeClassName="active" children="Om oss" />
                            </li>
                            <li className="list-inline-item">
                                <Link to="/kontaktinformation" activeClassName="active" children="Kontakt information" />
                            </li>
                        </ul>
                    </div>
                    <div className="d-none d-lg-block col-lg-3 text-right">
                        <a href="callto:0734000062" style={{ color: '#fff' }}>073 400 00 62</a> / <a href="mailto:info@antikviteten.se" style={{color: '#fff'}}>info@antikviteten.se</a>
                    </div>
                </div>
            </div>
            </section>
    );
};

export default Menu;