import React from 'react';

import { Link } from 'gatsby';

import * as Icon from 'react-feather';

const Footer = () => {
    return (
        <section className="footer">
        <div className="container container-fluid-lg">
          <div className="row contact-us">
            <div className="col-12 col-sm-6 text-center text-sm-left">
              <h4>Gratis värdering – Svar inom 24 timmar</h4>
            </div>
            <div className="col-12 col-sm-6 text-center text-sm-right mt-5 mt-sm-0">
              <Link to="/kontaktinformation/" className="btn btn-primary">Gratis värdering</Link>
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-md">
              <div className="header">Företaget</div>
              <ul className="list-unstyled">
                <li><Link to="/omoss">Om oss</Link></li>
                <li><a href="#0">Samarbetspartners</a></li>
              </ul>
            </div>

            <div className="col-6 col-md">
              <div className="header">Sortiment</div>
              <ul className="list-unstyled">
                <li><Link to="/sortiment">Produkter</Link></li>
              </ul>
            </div>

            <div className="col-6 col-md mt-5 mt-md-0">
              <div className="header">Kontakt information</div>
              <ul className="list-unstyled">
                <li><a href="callto:0734000062">073 400 00 62</a></li>
                <li><a href="mailto:info@antikviteten.se">info@antikviteten.se</a></li>
              </ul>
            </div>

            <div className="col-12 col-md mt-5 mt-md-0">
              <div className="header">Sociala medier</div>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/antikviteten.se/" target="_blank"><Icon.Instagram /></a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="logo">
                Antikviteten.se
              </div>
            </div>
          </div>

          <div className="row mt-5 text-center">
            <div className="col">
              <div className="copyright">
                {`Copyright © ${new Date().getFullYear()} Antik & Silver AB`}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Footer;