import React from 'react';

import Fade from "react-reveal/Fade";

const PostForm = () => (
    <div className="PostForm text-left" style={{ maxWidth: '720px', margin: '0 auto', marginTop: '80px' }}>
        <form method="post" name="product-valuation" data-netlify="true">
            {/* You still need to add the hidden input with the form name to your JSX form */}
            <input type="hidden" name="form-name" value="product-valuation" />
            <div class="form-row">
                <div class="col">
                    <label>För- & efternamn *</label>
                    <input type="text" name="name" className="form-control" />
                </div>
            </div>

            <div class="form-row mt-4">
                <div class="col-12 col-lg-6">
                    <label>E-mailadress *</label>
                    <input type="text" name="email" className="form-control" />
                </div>

                <div class="col-12 col-lg-6 mt-4 mt-lg-0">
                    <label>Telefonnummer</label>
                    <input type="text" name="phone" className="form-control" />
                </div>
            </div>

            <div class="form-row mt-4">
                <div class="col">
                    <label>Bild(er) på pjäsen du vill ha värderad *</label>
                    <input type="file" name="images" multiple className="form-control" />
                </div>
            </div>

            <div class="form-row text-right">
                <div class="col">
                    <div className="button-group">
                        <button 
                            type="submit" 
                            className="btn btn-primary" 
                            children="Skicka in för värdering" 
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
)

const ContactForm = ({ text }) => (
    <section className="contact-form" style={{ backgroundImage: 'url("/header/02.jpg")' }}>
        <Fade up>
            <div className="container-fluid">
                <div className="row">
                    <div className="col text-center">
                        {text !== undefined && (
                            <div className="text">
                                {text.h1 !== undefined && (
                                    <Fade down>
                                        <h1>{text.h1}</h1>
                                    </Fade>
                                )}
                                {text.h2 !== undefined && (
                                    <Fade down>
                                        <h2>{text.h2}</h2>
                                    </Fade>
                                )}
                                {text.h3 !== undefined && (
                                    <Fade down>
                                        <h3>{text.h3}</h3>
                                    </Fade>
                                )}
                            </div>
                        )}
                        <PostForm />
                    </div>
                </div>
            </div>
        </Fade>
    </section>
)

export default ContactForm;